import { ReactElement } from 'react'

import { Home } from '@/contents'
import { Main } from '@/layouts'
import { makeStaticProps } from '@/lib/getStatic'
import { NextPageWithLayout } from '@/pages/_app'

const HomePage: NextPageWithLayout = () => <Home />

HomePage.getLayout = function getLayout(page: ReactElement) {
  return <Main>{page}</Main>
}
export default HomePage

const getStaticProps = makeStaticProps()

export { getStaticProps }
